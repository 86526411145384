import { responsive } from "@boxoffice/screenplay";

type Config = {
  slidesPerViewScale: (number | undefined)[];
};

const config: Config = {
  slidesPerViewScale: responsive({
    xs: 2,
    sm: 3,
    md: 4,
    lg: 4,
    xl: 4,
    xxl: 4,
  }),
};

export default config;
