/** @jsx jsx */
import { MovieGridItemPosterProps } from "gatsby-theme-movie-grid";
import React, { memo } from "react";
import MoviePoster from "shared/components/MoviePoster";
import PlayButton from "shared/components/PlayButton";
import { isMovieNew } from "shared/helpers/movie";
import { createModalPortal } from "shared/helpers/portal";
import useAnalytics from "shared/hooks/useAnalytics";
import useMovieTrailer from "shared/hooks/useMovieTrailer";
import { jsx } from "theme-ui";

import config from "../../config";
import sxStyles from "../../styles";

const MovieGridItemPoster: React.FC<MovieGridItemPosterProps> = ({
  movie,
  theater,
}) => {
  const analytics = useAnalytics();
  const [MovieTrailer, launchMovieTrailer, hasTrailer] = useMovieTrailer(movie);

  return (
    <div sx={{ position: "relative" }}>
      <MoviePoster
        poster={movie.poster}
        title={movie.title}
        sizes={config.imageWidth}
        ribbonMessage={
          isMovieNew(
            movie?.release,
            theater?.timeZone,
            theater?.showtimeWeekReleaseDay
          )
            ? "new"
            : undefined
        }
      />
      {hasTrailer && (
        <div
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();

            launchMovieTrailer();
            analytics.track("click", {
              category: "Video",
              label: "trailer",
              movieId: movie.id,
              movieTitle: movie.title || undefined,
            });
          }}
          sx={sxStyles.trailerButton}
        >
          <PlayButton />
        </div>
      )}
      {createModalPortal(MovieTrailer)}
    </div>
  );
};

export default memo(MovieGridItemPoster);
