/** @jsx jsx */
import { responsive } from "@boxoffice/screenplay";
import type { MovieNode, Theater } from "gatsby-theme-movie-grid";
import React, { memo } from "react";
import Link from "shared/components/Link";
import Truncate from "shared/components/Truncate";
import { Grid, jsx, Themed } from "theme-ui";

import sxStyles from "../styles";
import Poster from "./Poster";

export type Props = {
  movie: MovieNode;
  theater?: Theater | null;
};

const MovieGridItem: React.FC<Props> = ({ movie, theater }) => {
  return (
    <div sx={sxStyles.item}>
      <Link to={movie.path} title={movie.title || ""}>
        <Grid gap={3}>
          <Poster movie={movie} theater={theater} />
          <Themed.h2
            sx={{
              overflow: "hidden",
              fontSize: responsive({ xs: 2, lg: 3, xl: 4 }),
            }}
          >
            <Truncate lines={1}>{movie.title}</Truncate>
          </Themed.h2>
        </Grid>
      </Link>
    </div>
  );
};

export default memo(MovieGridItem);
