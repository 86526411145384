import { DateTime } from "luxon";

import { getReleaseWeekFromDateTime } from "../date/getReleaseWeekFromDateTime";

export const isMovieNew = (
  release?: string | null,
  timezone?: string | null,
  showtimeWeekReleaseDay?: number
): boolean => {
  if (!release || !showtimeWeekReleaseDay) return false;
  const now = DateTime.local().setZone(timezone || "UTC");

  const [startDateTime, endDateTime] = getReleaseWeekFromDateTime(
    now,
    showtimeWeekReleaseDay,
    false
  );

  return release >= startDateTime && release <= endDateTime;
};
