/** @jsx jsx */
import { getAspectRatioFromCountryCode } from "@boxoffice/screenplay";
import React, { memo } from "react";
import usePageContext from "shared/hooks/usePageContext";
import { AspectRatio, jsx } from "theme-ui";

import sxStyles from "../styles";

const Placeholder: React.FC = () => {
  const pageContext = usePageContext();

  return (
    <div sx={sxStyles.itemPlaceholder}>
      <AspectRatio
        ratio={getAspectRatioFromCountryCode(pageContext.intl.country.code)}
        sx={sxStyles.itemPlaceholder}
      />
    </div>
  );
};

export default memo(Placeholder);
