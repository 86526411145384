import { responsive } from "@boxoffice/screenplay";
import configOrig from "gatsby-theme-movie-grid/src/components/MovieGrid/config";

const config: typeof configOrig = {
  ...configOrig,
  imageWidth: responsive({
    xs: 284,
    sm: 190,
    md: 152,
    lg: 216,
    xl: 280,
    xxl: 344,
  }),
};

export default config;
