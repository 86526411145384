/** @jsx jsx */
import React, { memo, ReactNode } from "react";
import { jsx } from "theme-ui";

import sxStyles from "../styles";

export type Props = {
  children: ReactNode;
  isNested: boolean;
};

const MovieGridWrapper: React.FC<Props> = ({ children, isNested }) => {
  return (
    <div sx={isNested ? sxStyles.wrapperNested : sxStyles.wrapper}>
      {children}
    </div>
  );
};

export default memo(MovieGridWrapper);
