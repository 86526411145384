/** @jsx jsx */
import { graphql } from "gatsby";
import { Carousel } from "gatsby-theme-carousel";
import { HeroCarousel } from "gatsby-theme-hero-carousel";
import { MovieGrid } from "gatsby-theme-movie-grid";
import orderBy from "lodash/orderBy";
import React, { memo } from "react";
import Bleed from "shared/components/Bleed";
import usePageContext from "shared/hooks/usePageContext";
import useThemeUI from "shared/hooks/useThemeUI";
import { jsx } from "theme-ui";

import WidgetWrapper from "../../WidgetWrapper";
import type { WidgetProps, WidgetUnion } from "../types";

interface Props extends WidgetProps {
  widget: Extract<WidgetUnion, { __typename: "WidgetMovies" }>;
}

const MoviesWidget: React.FC<Props> = ({
  isFirst,
  isLast,
  isNested,
  shouldBleed,
  containerSize,
  widget,
  prevWidget,
  nextWidget,
}) => {
  const { theaterId } = usePageContext();
  const { theme } = useThemeUI();

  const movies = widget.moviesShape?.movies
    ? orderBy(
        theaterId
          ? widget.moviesShape.movies.filter((movie) =>
              movie?.movie?.theaters?.find(
                (theaterRelation) => theaterRelation?.theater?.id === theaterId
              )
            )
          : widget.moviesShape.movies,
        [
          (movieNode) => movieNode?.movie?.release || "",
          (movieNode) => movieNode?.movie?.title,
        ],
        widget.moviesShape?.type === "NOW_PLAYING" ? ["desc", "asc"] : "asc"
      )
    : widget.moviesShape?.movies;

  switch (widget.moviesShape?.display) {
    case "heroCarousel": {
      return movies?.length ? (
        <WidgetWrapper
          isFirst={isFirst}
          isLast={isLast}
          isNested={isNested}
          containerSize={"FULL"}
          widget={widget}
          prevWidget={prevWidget}
          nextWidget={nextWidget}
          contained={false}
        >
          <Bleed
            top={
              shouldBleed && isFirst
                ? // FIXME: type this
                  // @ts-expect-error no type for sectionVerticalGutter
                  theme.sizes?.sectionVerticalGutter
                : undefined
            }
            bottom={
              shouldBleed && isLast
                ? // FIXME: type this
                  // @ts-expect-error no type for sectionVerticalGutter
                  theme.sizes?.sectionVerticalGutter
                : undefined
            }
            horizontal={
              shouldBleed
                ? // FIXME: type this
                  // @ts-expect-error no type for containerGutter
                  theme.sizes?.containerGutter
                : undefined
            }
          >
            <HeroCarousel
              isNested={isNested}
              slides={movies.map((movieNode) => {
                return {
                  caption: movieNode?.movie?.title || "—",
                  image: {
                    src: movieNode?.movie?.pictures?.[0],
                    alt: movieNode?.movie?.title,
                  },
                  movie: movieNode?.movie,
                };
              })}
            />
          </Bleed>
        </WidgetWrapper>
      ) : null;
    }
    case "carousel":
      return (
        <WidgetWrapper
          isFirst={isFirst}
          isLast={isLast}
          isNested={isNested}
          containerSize={containerSize}
          widget={widget}
          prevWidget={prevWidget}
          nextWidget={nextWidget}
          contained={containerSize !== "FULL"}
        >
          <Bleed
            horizontal={
              shouldBleed
                ? // FIXME: type this
                  // @ts-expect-error no type for containerGutter
                  theme.sizes?.containerGutter
                : undefined
            }
          >
            <Carousel
              collection={widget.moviesShape.type}
              nodes={
                movies
                  ?.filter((movieNode) => movieNode?.movie)
                  .map((movieNode) => movieNode?.movie || null) || null
              }
            />
          </Bleed>
        </WidgetWrapper>
      );
    case "grid": {
      return (
        <WidgetWrapper
          isFirst={isFirst}
          isLast={isLast}
          isNested={isNested}
          containerSize={containerSize}
          widget={widget}
          prevWidget={prevWidget}
          nextWidget={nextWidget}
        >
          <MovieGrid
            isNested={isNested}
            collection={widget.moviesShape.type}
            nodes={
              movies
                ?.filter((movieNode) => movieNode?.movie)
                .map((movieNode) => movieNode?.movie || null) || null
            }
          />
        </WidgetWrapper>
      );
    }
    default:
      return null;
  }
};

export const query = graphql`
  fragment WidgetMovies on WidgetMovies {
    id
    __typename
    moviesShape {
      display
      type
      movies {
        movie {
          id
          path
          title
          casting
          certificate
          direction
          genres
          pictures
          poster
          release
          runtime
          synopsis
          trailer {
            HD
            SD
          }
          theaters {
            collections
            theater {
              id
              name
              timeZone
              showtimeWeekReleaseDay
            }
          }
        }
      }
    }
  }
`;

export default memo(MoviesWidget);
