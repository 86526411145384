/** @jsx jsx */

// eslint-disable-next-line import/no-extraneous-dependencies
import { responsive } from "@boxoffice/screenplay";
import { ClassNames } from "@emotion/react";
import { useBreakpointIndex } from "@theme-ui/match-media";
import { MovieGrid } from "gatsby-theme-movie-grid";
import React, {
  Fragment,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { Swiper, SwiperClass, SwiperSlide } from "shared/components/Swiper";
import ChevronLeftSquare from "shared/components/Swiper/Arrow/ChevronLeftSquare";
import ChevronRightSquare from "shared/components/Swiper/Arrow/ChevronRightSquare";
import useIntl from "shared/helpers/i18n/useIntl";
import { loadable } from "shared/helpers/loadableComponent";
import usePageContext from "shared/hooks/usePageContext";
import { useSelectedTheaterId } from "shared/hooks/useSelectedTheaterId";
import useThemeUI from "shared/hooks/useThemeUI";
import {
  Container,
  css as toEmotionCSS,
  jsx,
  Themed,
  ThemeUIStyleObject,
} from "theme-ui";

import config from "./config";
import messages from "./i18n";
import sxStyles from "./styles";
import type { MovieNode } from "./types";

const ScheduleOptionsBar = loadable(
  () => import("gatsby-theme-schedule-options-bar"),
  {
    resolveComponent: (components) => components.ScheduleOptionsBar,
  }
);

export type Props = {
  nodes?: readonly (MovieNode | null)[] | null;
  collection: "NOW_PLAYING" | "COMING_SOON";
};

const COMMON_ARROW_SX: ThemeUIStyleObject = {
  position: "absolute",
  zIndex: 1,
  top: "50%",
  transform: "translateY(-100%)",
  fontSize: responsive({ xs: 5, md: 6 }),
  userSelect: "none",
  cursor: "pointer",
};

const CarouselComponent: React.FC<Props> = ({ nodes, collection }) => {
  const pageContext = usePageContext();
  const breakPointIndex = useBreakpointIndex();
  const [selectedTheaterId] = useSelectedTheaterId();
  const { formatMessage } = useIntl();
  const [filteredMovies, setFilteredMovies] = useState<
    readonly (MovieNode | null)[] | null
  >(null);
  const { theme } = useThemeUI();
  const swiperInstanceRef = useRef<SwiperClass | null>(null);

  const theaterId = pageContext?.theaterId || selectedTheaterId;

  const goToFirstLoopSlide = useCallback(() => {
    try {
      swiperInstanceRef.current?.slideToLoop?.(0, 0);
    } catch (error) {
      // something not interesting happened
    }
  }, [swiperInstanceRef]);

  useEffect(() => {
    if (nodes) {
      setFilteredMovies(
        !pageContext.isSingleLocation && theaterId
          ? nodes?.filter((movie) =>
              movie?.theaters?.find(
                (theaterRelation) =>
                  theaterRelation?.theater?.id === theaterId &&
                  theaterRelation.collections?.includes(collection)
              )
            )
          : nodes
      );
    }

    if (swiperInstanceRef.current) {
      setTimeout(() => {
        goToFirstLoopSlide();
      }, 0);
    }
  }, [theaterId, nodes]);

  if (!filteredMovies) {
    return null;
  }

  const slidesPerView = (config.slidesPerViewScale[breakPointIndex] ??
    config.slidesPerViewScale[config.slidesPerViewScale.length - 1]) as number;

  const RenderSelector = () =>
    !pageContext.isSingleLocation && !pageContext?.theaterId ? (
      <Container>
        <div sx={sxStyles.scheduleOptionsBar}>
          <ScheduleOptionsBar includeTheaterSelector theaterId={undefined} />
        </div>
      </Container>
    ) : null;

  if (!pageContext.isSingleLocation && !filteredMovies.length) {
    return (
      <Fragment key={theaterId}>
        <RenderSelector />
        <Themed.p sx={{ textAlign: "center" }}>
          {formatMessage(messages.noMovies)}
        </Themed.p>
      </Fragment>
    );
  }

  const slideGroups: MovieNode[][] = [];
  let stage = 0;

  filteredMovies.forEach((filteredMovie, index) => {
    if (index !== 0 && index % slidesPerView === 0) {
      stage = stage + 1;
    }

    if (!slideGroups[stage]) {
      slideGroups[stage] = [];
    }

    if (filteredMovie) {
      slideGroups[stage].push(filteredMovie);
    }
  });

  return (
    <Fragment>
      <RenderSelector />

      <ClassNames>
        {({ css }) => {
          const swiperClassName = css(
            toEmotionCSS({
              maxWidth: (theme) => {
                const containerMaxWidth =
                  // @ts-expect-error can't define maxWidth
                  theme.layout?.container?.maxWidth;

                if (Array.isArray(containerMaxWidth)) {
                  return containerMaxWidth.map((maxWidth: string, index) => {
                    if (
                      theme.layout?.container &&
                      "paddingX" in theme.layout.container
                    ) {
                      const containerPaddingX =
                        theme.layout?.container?.paddingX;

                      return `calc(${maxWidth} - ${
                        Array.isArray(containerPaddingX)
                          ? containerPaddingX?.[index] || 0
                          : containerPaddingX
                      } * 2)`;
                    }

                    return undefined;
                  });
                }

                return containerMaxWidth;
              },
            })(theme)
          );

          return (
            <div sx={{ position: "relative" }}>
              <Swiper
                onInit={(instance) => {
                  swiperInstanceRef.current = instance || null;
                }}
                onResize={() => {
                  goToFirstLoopSlide();
                }}
                loop={slideGroups.length > 1}
                centeredSlides
                slidesPerView={"auto"}
                spaceBetween={32}
                freeMode
                grabCursor
              >
                {slideGroups.map((group, index) => {
                  return (
                    <SwiperSlide
                      key={`${theaterId}-${index}`}
                      className={swiperClassName}
                    >
                      <MovieGrid
                        isNested={false}
                        collection={collection}
                        nodes={group}
                        withTheaterSelector={false}
                      />
                    </SwiperSlide>
                  );
                })}
              </Swiper>
              {slideGroups.length > 1 && (
                <div
                  sx={{
                    ...COMMON_ARROW_SX,
                    left: 0,
                  }}
                  onClick={() => {
                    swiperInstanceRef.current?.slidePrev?.();
                  }}
                >
                  <ChevronLeftSquare />
                </div>
              )}
              {slideGroups.length > 1 && (
                <div
                  sx={{
                    ...COMMON_ARROW_SX,
                    right: 0,
                  }}
                  onClick={() => {
                    swiperInstanceRef.current?.slideNext?.();
                  }}
                >
                  <ChevronRightSquare />
                </div>
              )}
            </div>
          );
        }}
      </ClassNames>
    </Fragment>
  );
};

export default memo(CarouselComponent);
