import { defineMessages } from "shared/helpers/i18n/defineMessages";

const messages = defineMessages({
  noMovies: {
    id: "movies.carousel.no-movies-label",
    defaultMessage: "No movies correspond to your selection",
  },
});

export default messages;
