import { responsive } from "@boxoffice/screenplay";

type Config = {
  slidesPerViewScale: (number | undefined)[];
  imageWidth: (number | undefined)[];
};

const config: Config = {
  slidesPerViewScale: responsive({
    xs: 2,
    sm: 3,
    md: 4,
    lg: 4,
    xl: 4,
    xxl: 4,
  }),
  imageWidth: responsive({
    xs: 284,
    sm: 190,
    md: 152,
    lg: 216,
    xl: 280,
    xxl: 344,
  }),
};

export default config;
