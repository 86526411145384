/** @jsx jsx */
import { useBreakpointIndex } from "@theme-ui/match-media";
import { ClosureMessage } from "gatsby-theme-warning-message";
import times from "lodash/times";
import React, { Fragment, memo, useEffect, useState } from "react";
import useIntl from "shared/helpers/i18n/useIntl";
import { loadable } from "shared/helpers/loadableComponent";
import usePageContext from "shared/hooks/usePageContext";
import { useSelectedTheaterId } from "shared/hooks/useSelectedTheaterId";
import { jsx } from "theme-ui";

import config from "./config";
import messages from "./i18n";
import Item from "./Item";
import Placeholder from "./Placeholder";
import sxStyles from "./styles";
import type { MovieNode } from "./types";
import Wrapper from "./Wrapper";

const ScheduleOptionsBar = loadable(
  () => import("gatsby-theme-schedule-options-bar"),
  {
    resolveComponent: (components) => components.ScheduleOptionsBar,
  }
);

export type Props = {
  isNested: boolean;
  nodes?: readonly (MovieNode | null)[] | null;
  collection: "NOW_PLAYING" | "COMING_SOON";
  withTheaterSelector?: boolean;
};

const MovieGrid: React.FC<Props> = ({
  nodes,
  isNested,
  collection,
  withTheaterSelector = true,
}) => {
  const pageContext = usePageContext();
  const { formatMessage } = useIntl();
  const [selectedTheaterId] = useSelectedTheaterId();
  const breakPointIndex = useBreakpointIndex();
  const [filteredMovies, setFilteredMovies] = useState<
    readonly (MovieNode | null)[] | null
  >(null);

  useEffect(() => {
    if (nodes) {
      setFilteredMovies(
        !pageContext.isSingleLocation && selectedTheaterId
          ? nodes?.filter((movie) =>
              movie?.theaters?.find(
                (theaterRelation) =>
                  theaterRelation?.theater?.id === selectedTheaterId &&
                  theaterRelation.collections?.includes(collection)
              )
            )
          : nodes
      );
    }
  }, [selectedTheaterId, nodes]);

  const theater =
    !pageContext.isSingleLocation && selectedTheaterId
      ? filteredMovies?.[0]?.theaters?.find(
          (theaterRelation) =>
            theaterRelation?.theater?.id === selectedTheaterId
        )?.theater
      : filteredMovies?.[0]?.theaters?.[0]?.theater;

  const slidesPerView = (config.slidesPerViewScale[breakPointIndex] ??
    config.slidesPerViewScale[config.slidesPerViewScale.length - 1]) as number;

  return (
    <Fragment>
      {withTheaterSelector && !pageContext.isSingleLocation && (
        <Fragment>
          <div sx={sxStyles.scheduleOptionBar}>
            <ScheduleOptionsBar includeTheaterSelector theaterId={undefined} />
          </div>
          <ClosureMessage theaterId={selectedTheaterId} />
        </Fragment>
      )}

      {!pageContext.isSingleLocation && !filteredMovies?.length ? (
        <p sx={{ textAlign: "center" }}>{formatMessage(messages.noMovies)}</p>
      ) : null}

      <Wrapper isNested={isNested}>
        {filteredMovies?.map((movie) => {
          if (!movie) {
            return null;
          }

          return <Item key={movie.id} movie={movie} theater={theater} />;
        })}
        {times(slidesPerView - (filteredMovies?.length || 0), (index) => {
          return (
            <div key={index}>
              <Placeholder />
            </div>
          );
        })}
      </Wrapper>
    </Fragment>
  );
};

export default memo(MovieGrid);
