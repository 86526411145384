import { responsive } from "@boxoffice/screenplay";
import sxStylesOrig from "gatsby-theme-movie-grid/src/components/MovieGrid/styles";
import { ThemeUIStyleObject } from "theme-ui";

const sxStyles: typeof sxStylesOrig & { trailerButton: ThemeUIStyleObject } = {
  ...sxStylesOrig,
  item: {
    ...sxStylesOrig.item,
    position: "relative",
    minWidth: 0,
  },
  wrapper: {
    ...sxStylesOrig.wrapper,
    columnGap: responsive({ xs: 4 }),
    rowGap: responsive({ xs: 4, lg: 5 }),
  },
  trailerButton: {
    position: "absolute",
    top: "1rem",
    right: "1rem",
    borderRadius: "maximum",
  },
};

export default sxStyles;
