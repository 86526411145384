import { responsive } from "@boxoffice/screenplay";
import { getTinyColor } from "shared/helpers/colors";
import { ThemeUIStyleObject } from "theme-ui";

const wrapper: ThemeUIStyleObject = {
  display: "grid",
  gridTemplateColumns: responsive({
    xs: "1fr 1fr",
    sm: "1fr 1fr 1fr",
    md: "1fr 1fr 1fr 1fr",
  }),
  gap: responsive({ xs: 2, md: 4 }),
  rowGap: responsive({ xs: 2, md: 5 }),
};

const wrapperNested: ThemeUIStyleObject = {
  ...wrapper,
  gridTemplateColumns: responsive({
    xs: "1fr 1fr",
    sm: "1fr 1fr 1fr",
    md: "1fr 1fr 1fr 1fr",
  }),
};

const sxStyles: Record<
  | "scheduleOptionBar"
  | "wrapper"
  | "wrapperNested"
  | "item"
  | "itemTitle"
  | "itemPlaceholder",
  ThemeUIStyleObject
> = {
  scheduleOptionBar: { marginBottom: 4 },
  wrapper,
  wrapperNested,
  item: { textAlign: "center" },
  itemTitle: { fontSize: responsive({ xs: 2, lg: 3 }) },
  itemPlaceholder: {
    backgroundColor: (theme) => {
      const bg = getTinyColor(theme.rawColors?.background);
      return bg.isLight()
        ? bg.shade(5).toHexString()
        : bg.tint(5).toHexString();
    },
    borderRadius: "medium",
    overflow: "hidden",
  },
};

export default sxStyles;
