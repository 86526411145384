import { getTinyColor } from "shared/helpers/colors";
import { ThemeUIStyleObject } from "theme-ui";

const sxStyles: Record<
  | "scheduleOptionsBar"
  | "carouselItem"
  | "carouselItemLink"
  | "carouselItemLinkLabel"
  | "carouselItemPlaceholder"
  | "carouselItemPlaceholderPoster",
  ThemeUIStyleObject
> = {
  scheduleOptionsBar: { marginBottom: 4 },
  carouselItem: {
    textAlign: "center",
    maxWidth: "325px",
    margin: "0 auto",
    position: "relative",
  },
  carouselItemLink: {
    variant: "buttons.primary",
  },
  carouselItemLinkLabel: {
    display: "block",
    textAlign: "center",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    width: "100%",
  },
  carouselItemPlaceholder: {
    textAlign: "center",
    maxWidth: "325px",
    margin: "0 auto",
  },
  carouselItemPlaceholderPoster: {
    backgroundColor: (theme) => {
      const bg = getTinyColor(theme.rawColors?.background);
      return bg.isLight()
        ? bg.shade(5).toHexString()
        : bg.tint(5).toHexString();
    },
    borderRadius: "medium",
    overflow: "hidden",
  },
};

export default sxStyles;
